<template>
	<div>
		<b-card>
			<b-form>
				<b-row>
					<b-col>
						<!-- <b-table></b-table> -->
					</b-col>
					<b-col cols="12 mb-2">
						<label for="security">
							<strong>Variación de Resultados</strong>
						</label>
						<b-table
							ref="refVariationTable"
							:fields="variationsTable.fields"
							:items="variationsTable.items"
							bordered
							show-empty
							empty-text="No se encontraron registros coincidentes"
						>
							<template #thead-top>
								<b-tr>
									<b-th></b-th>
									<b-th
										colspan="2"
										class="text-center"
									>RESULTADO DEL MES ACTUAL</b-th>
									<b-th
										colspan="2"
										class="text-center"
									>RESULTADO DEL MES ANTERIOR</b-th>
									<b-th></b-th>
								</b-tr>
							</template>

							<template #table-busy>
								<div class="text-center text-primary my-2">
									<b-spinner class="align-middle mb-1"></b-spinner>
									<div>
										<b>CARGANDO</b>
									</div>
								</div>
							</template>

							<template #cell(observation)="data">
								<div>
									<b-button
										variant="outline-primary"
										class="btn-icon rounded-circle"
										@click="openCommentModal(data.item.observation)"
									>
										<feather-icon icon="MessageSquareIcon" />
									</b-button>
								</div>
							</template>
						</b-table>
					</b-col>

					<b-col cols="12 mb-2">
						<b-form-group>
							<label for="colpa">
								<strong>Colpa (comentario general)</strong>
							</label>

							<b-form-textarea
								id="colpa"
								v-model="documentData.colpa"
								placeholder="Ingrese texto aquí"
								rows="3"
							/>
						</b-form-group>
					</b-col>

					<b-col cols="12 mb-2">
						<b-form-group>
							<label for="rotation">
								<strong>Rotación (comentario general)</strong>
							</label>

							<b-form-textarea
								id="rotation"
								v-model="documentData.rotation"
								placeholder="Ingrese texto aquí"
								rows="3"
							/>
						</b-form-group>
					</b-col>

					<b-col cols="12 mb-2">
						<b-form-group>
							<label for="conclusion">
								<strong>Conclusiones y Recomendaciones</strong>
							</label>

							<b-form-textarea
								id="conclusion"
								v-model="documentData.conclusion"
								placeholder="Ingrese texto aquí"
								rows="8"
							/>
						</b-form-group>
					</b-col>

					<b-col
						cols="12"
						class="my-1"
					>
						<b-alert
							variant="info"
							show
						>
							<div class="alert-body small">
								<feather-icon
									icon="InfoIcon"
									class="mr-50"
								/>
								<a
									class="text-info"
									href="/plantillas/plantilla_anexo.xlsx"
								>
									Descargar
									<strong>plantilla para anexos</strong>
								</a>
							</div>
						</b-alert>
					</b-col>

					<b-col cols="12 mb-2">
						<b-form-group>
							<label for="security">
								<strong>ANEXO: Cuadro general de evaluación de desempeño (imagen)</strong>

								<b-link
									v-if="documentData.file_screenshot_url"
									class="ml-1"
									href="#"
									target="_blank"
									title="Descargar"
								>
									<feather-icon icon="PaperclipIcon" />
								</b-link>
							</label>
							<b-form-file
								v-model="documentData.file_screenshot"
								placeholder="Seleccione un archivo o arrástrelo aquí..."
								drop-placeholder="Suelte el archivo aquí ..."
								accept=".jpg, .png, .jpeg"
							/>
						</b-form-group>
					</b-col>

					<b-col cols="12 mb-2">
						<b-form-group>
							<label for="security">
								<strong>ANEXO: Detalle de Improductivos UP MN (plantilla)</strong>
								<b-link
									v-if="documentData.file_template_url"
									class="ml-1"
									href="#"
									target="_blank"
									title="Descargar"
								>
									<feather-icon icon="PaperclipIcon" />
								</b-link>
							</label>

							<b-form-file
								v-model="documentData.file_template"
								placeholder="Seleccione un archivo o arrástrelo aquí..."
								drop-placeholder="Suelte el archivo aquí ..."
								accept=".xlsx"
							/>
						</b-form-group>
					</b-col>

					<b-col cols="12 mb-2">
						<b-form-group>
							<label for="security">
								<strong>ANEXO: Detalle de Improductivos UP SM (plantilla)</strong>
								<b-link
									v-if="documentData.file_template_two_url"
									class="ml-1"
									href="#"
									target="_blank"
									title="Descargar"
								>
									<feather-icon icon="PaperclipIcon" />
								</b-link>
							</label>

							<b-form-file
								v-model="documentData.file_template_two"
								placeholder="Seleccione un archivo o arrástrelo aquí..."
								drop-placeholder="Suelte el archivo aquí ..."
								accept=".xlsx"
							/>
						</b-form-group>
					</b-col>
				</b-row>
			</b-form>
		</b-card>

		<b-col cols="12 mt-2">
			<b-button-loading
				text="GENERAR REPORTE PDF"
				variant="outline-primary"
				@process-action="generateDocument"
				:block="true"
				size="lg"
			/>
		</b-col>

		<b-modal
			ref="refObservationModal"
			title-tag="div"
			header-bg-variant="primary"
			header-text-variant="white"
			title="OBSERVACIONES"
			cancel-title="Cerrar"
			no-close-on-backdrop
			no-close-on-esc
			hide-header-close
			size="lg"
			centered
		>
			<b-row>
				<b-col
					cols="12"
					class="mb-2"
				>
					<b-form-group>
						<label
							for="security"
							class="text-primary"
						>
							<strong>Observación</strong>
						</label>
						<b-form-textarea
							id="security"
							v-model="observationModal"
							placeholder="Deje observación aquí ..."
							rows="4"
						/>
					</b-form-group>
				</b-col>
			</b-row>
			<template #modal-footer="{ cancel }">
				<b-button
					@click="refObservationModal.hide()"
					variant="primary"
				>GUARDAR</b-button>

				<b-button
					v-ripple.400="'rgba(186, 191, 199, 0.15)'"
					type="button"
					variant="outline-secondary"
					class="ml-2"
					@click="cancel"
				>CERRAR</b-button>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { ref, onBeforeMount, onUnmounted } from "@vue/composition-api"
import BButtonLoading from "@core/components/b-button-loading/BButtonLoading.vue"
import documentStoreModule from "../documentStoreModule"
import useNotifications from "@notifications"
import Ripple from "vue-ripple-directive"
import VueSweetalert2 from "vue-sweetalert2"
import store from "@/store"
import Vue from "vue"
import "animate.css"

Vue.use(VueSweetalert2)

export default {
	components: {
		BButtonLoading
	},
	directives: {
		Ripple
	},
	setup() {
		const { swalNotification, toastNotification } = useNotifications()

		const refObservationModal = ref(null)
		const observationModal = ref(null)

		const refVariationTable = ref(null)
		const variationsTable = ref({
			fields: [],
			items: []
		})

		const documentData = ref({
			colpa: null,
			rotation: null,
			conclusion: null,
			file_screenshot: null,
			file_screenshot_url: null,
			file_template: null,
			file_template_url: null,
			file_template_two: null,
			file_template_two_url: null
		})

		const DOCUMENT_APP_STORE_MODULE_NAME = "app-document"

		// REGISTER MODULE
		if (!store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME))
			store.registerModule(DOCUMENT_APP_STORE_MODULE_NAME, documentStoreModule)

		// UNREGISTER ON LEAVE
		onUnmounted(() => {
			if (store.hasModule(DOCUMENT_APP_STORE_MODULE_NAME))
				store.unregisterModule(DOCUMENT_APP_STORE_MODULE_NAME)
		})

		const styleColumns = {
			thClass: "text-center",
			tdClass: "text-center"
		}

		variationsTable.value.fields = [
			{ key: "provider", label: "ECM", ...styleColumns },
			{
				key: "current_position",
				label: "POSICIÓN",
				...styleColumns
			},
			{
				key: "current_performance",
				label: "EMPRESA",
				...styleColumns
			},
			{
				key: "past_position",
				label: "POSICIÓN",
				...styleColumns
			},
			{
				key: "past_performance",
				label: "EMPRESA",
				...styleColumns
			},
			{ key: "observation", label: "Observaciones", ...styleColumns }
		]

		variationsTable.value.items = [
			{
				provider: "MVM DRILLING",
				current_position: 6,
				current_performance: "EMPRESA PROMETEDORA",
				past_position: 6,
				past_performance: "EMPRESA PROMETEDORA",
				observation: "Mantiene su posición respecto al periodo anterior"
			},
			{
				provider: "REMICSA",
				current_position: 8,
				current_performance: "EMPRESA ÉLITE",
				past_position: 7,
				past_performance: "EMPRESA ÉLITE",
				observation:
					"Su resultado en el mes de marzo se ha visto afectado principalmente por cumplimiento de sus obligaciones laborales, el cual ha sido de 88%. Cabe indicar que el nivel de cumplimiento del mes de febrero fue de 90%."
			},
			{
				provider: "J & S (MN)",
				current_position: 1,
				current_performance: "EMPRESA DE DESEMPEÑO REDUCIDO",
				past_position: 3,
				past_performance: "EMPRESA DE DESEMPEÑO REDUCIDO",
				observation:
					"J&S, terminó en el mes anterior en la casilla 01 y ahora ha subido a la casilla 03, debido a que ha mejorado su resultado en el indicador de seguridad al no haber eventos que afecten el indicador de seguridad."
			}
		]

		const openCommentModal = (observation) => {
			console.log(observation)
			observationModal.value = observation

			refObservationModal.value.show()
		}

		const mainHeaders = [
			{ label: "ECM", rowspan: 2 },
			{ label: "POSICIÓN SEPTIEMBRE 2024", colspan: 2 },
			{ label: "POSICIÓN OCTUBRE 2024", colspan: 2 },
			{ label: "OBSERVACIONES", rowspan: 2 }
		]

		// Subcabeceras dinámicas
		const subHeaders = [
			{ label: "Posición" },
			{ label: "Desempeño" },
			{ label: "Posición" },
			{ label: "Desempeño" }
		]

		const fields = [
			{ key: "provider", label: "ECM" },
			{ key: "current_position", label: "Posición", isSubheader: true },
			{ key: "current_performance", label: "Desempeño", isSubheader: true },
			{ key: "past_position", label: "Posición", isSubheader: true },
			{ key: "past_performance", label: "Desempeño", isSubheader: true },
			{ key: "observation", label: "Observaciones" }
		]

		// Datos de ejemplo
		const items = [
			{
				provider: "MVM DRILLING",
				current_position: 6,
				current_performance: "EMPRESA PROMETEDORA",
				past_position: 6,
				past_performance: "EMPRESA PROMETEDORA",
				observation: "Mantiene su posición respecto al periodo anterior"
			},
			{
				provider: "REMICSA",
				current_position: 8,
				current_performance: "EMPRESA ÉLITE",
				past_position: 7,
				past_performance: "EMPRESA ÉLITE",
				observation:
					"Su resultado en el mes de marzo se ha visto afectado principalmente por cumplimiento de sus obligaciones laborales."
			},
			{
				provider: "J & S (MN)",
				current_position: 1,
				current_performance: "EMPRESA DE DESEMPEÑO REDUCIDO",
				past_position: 3,
				past_performance: "EMPRESA DE DESEMPEÑO REDUCIDO",
				observation:
					"J&S, terminó en el mes anterior en la casilla 01 y ahora ha subido a la casilla 03."
			}
		]

		const generateDocument = () => {
			Vue.swal({
				title: "¿Está seguro(a) de generar el reporte?",
				html: "<small>¡Si no lo está, puede cancelar la acción!</small>",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí, generar!",
				cancelButtonText: "Cancelar",
				customClass: {
					confirmButton: "btn btn-primary",
					cancelButton: "btn btn-outline-danger ml-1"
				},
				showClass: {
					popup: "animate__animated animate__tada"
				},
				buttonsStyling: false
			}).then((result) => {
				if (result.value) {
					store
						.dispatch("app-document/generateDocument", { id })
						.then((response) => {
							refetchData()
							swalNotification(response.data.message)
						})
						.catch((error) => {
							const message =
								error.response.data.message || "Error al generar el reporte"
							swalNotification(message, false)
						})
				}
			})
		}

		return {
			fields,
			items,
			mainHeaders,
			subHeaders,
			documentData,

			refObservationModal,
			refVariationTable,
			variationsTable,
			observationModal,

			openCommentModal,
			generateDocument
		}
	}
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>